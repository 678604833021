//rafce 
import * as React from 'react';   
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';  

const Footer = () => { 


  return (
    <footer >  
        <div style={{textAlign:'center', margin:'5px'}}>
          <span style={{textAlign:'center', margin:'0 6px', padding:'0 6px'  , borderRight:'1px solid'}} >Settings</span>
          <span style={{textAlign:'center'}} >My Account</span>
        </div>

        <div style={{textAlign:'center', margin:'5px'}}>
          <small>© 2024</small>
        </div>
        
        <Grid container sx={{padding:'5px'}} >
            <Grid item xs={6} sx={{ textAlign:'right' , borderRight:'1px solid' , padding:'7px'  }}> 
              <img src={"./logo.jpg"} style={{width:'40px'}} /> 
            </Grid>
            <Grid item xs={6} sx={{ textAlign:'left' }}>  
              <img src={"./terraLogo.jpg"} style={{width:'40px'}} /> 
            </Grid>
        </Grid>
    </footer>
  )
}

export default Footer
