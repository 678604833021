 
import axios from "axios";
import { configUrls } from "../../config/base"; 

async function getCompaniesAndGroups(UserIdGuid, SessionId) { 
 
    let url = 'https://mapsrv19.terra.gr/server/rest/services/APIs/3D_Tracking/FeatureServer/5/query'; 

    let companies; 
    let groups; 

    try {

        companies = await axios.get(
            url, 
            {
                headers: { 
                    "Content-Type": "application/json",  
                }, 
                withCredentials: false,
                params:{
                    where:'1=1',
                    geometryType:'esriGeometryPoint' , 
                    units:'esriSRUnit_Meter',
                    outFields:'companyname',
                    returnGeometry:false,
                    returnDistinctValues:true, 
                    f:'pjson'
                }
            },
        );//get 

        groups = await axios.get(
            url, 
            {
                headers: { 
                    "Content-Type": "application/json",  
                }, 
                withCredentials: false,
                params:{
                    where:'1=1',
                    geometryType:'esriGeometryPoint' , 
                    units:'esriSRUnit_Meter',
                    outFields:'groupname,companyname ',
                    returnGeometry:false,
                    returnDistinctValues:true, 
                    f:'pjson'
                }
            },
        );//get  


        let companiesGroups = [];

        companiesGroups.push({
            'company':'All Companies and Groups',
            'display':1,
            'select':true,
            'groups':[]
        })


        companies.data.features.forEach(element => {
            let groupsOfComapny = groups.data.features.filter((el)=>element.attributes.companyname == el.attributes.companyname);
            let groupsnames = groupsOfComapny.map((el)=>{return {'group':el.attributes.groupname, 'select':false}})
            companiesGroups.push({
                'company':element.attributes.companyname,
                'display':1,
                'select':false,
                'groups':groupsnames
            })

        });


        // console.log('companies API response ', response);   

        // let companiesGroups = [];
        
        // let originalResponse = response.data.features

        // originalResponse.forEach(element => {
        //     let groupsOfComapny = originalResponse.filter((el)=>element.attributes.companyname == el.attributes.companyname);
        //     groupsOfComapny.map((el)=>el.attributes.groupname);
        //     companiesGroups.push({
        //         'company':element.attributes.companyname, 
        //         'groups':groupsOfComapny
        //     })
        //     // element.attributes.chosen = false; 
        //     // element.attributes.display = 1;
        // });
 
         

        return companiesGroups;
    }//try 
    catch (error) {
        console.log('error companies  ',  error);
        return error;
    }
       
}



export { getCompaniesAndGroups };
