//rafce 
import Map from "@arcgis/core/Map.js"; 
import config from "@arcgis/core/config.js";
import MapView from "@arcgis/core/views/MapView.js"; 
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle.js";
import Search from "@arcgis/core/widgets/Search.js"; 
import Legend  from "@arcgis/core/widgets/Legend.js"; 
import Expand from "@arcgis/core/widgets/Expand.js"; 
import LayerList from "@arcgis/core/widgets/LayerList.js"; 
import StreamLayer from "@arcgis/core/layers/StreamLayer.js";  
import { Box , Button, Drawer, Checkbox } from "@mui/material"; 
import {memo, useEffect, useState, useRef, useMemo, useCallback, useReducer } from "react"; 
import {getUnits} from '../common/api/unitsAPI.js' 
import { useUserStore } from "../common/store/user";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'; 
import Grid from '@mui/material/Grid'; 
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect.js";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter.js";
import Extent from "@arcgis/core/geometry/Extent.js"; 
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Query from "@arcgis/core/rest/support/Query.js";
import TimeSlider from "@arcgis/core/widgets/TimeSlider.js";
import { useMeasurements } from "../common/store/measurements.js";
import Popup from "@arcgis/core/widgets/Popup.js";
import TruckList from "../components/TrackList.js";
import AlertsList from "../components/AlertsList.js"; 
import { useParams } from 'react-router-dom';
import { useTrucks } from "../common/store/trucks.js";
import { getUnitsData } from "../common/trucks/getTrucks.js";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';  
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from "moment-timezone";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs' ;  
import Alert from '@mui/material/Alert'; 
import Chart from 'react-apexcharts'

const HistoryMap = ({truck , startEndDateDP}) => {   

    
    const [layerLoaded , setLayerLoaded] = useState(false);       
    const [showNoData , setShowNoData] = useState(false);     
    const [resultFeatures , setResultFeatures] = useState([]);    
    const [chartState , setChartState] = useState({
      options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: [0, 1]
        }
      },
      series: [{
        name: 'series-1',
        data: [5,6]
      }]
    });    


    const [pieState , setPieState] = useState({ 
      series: [0],
      options: {
        chart: {
          height: 320,
          type: 'pie',
        },
        labels: ['0'] 
      }
      
    });    




    

     
      
 

  const mapcontHistory = useRef();    
  const webmapHistory = useRef();
  const view = useRef();
  const g4s = useRef();
  const g4sLayerView = useRef();
  const speedingPointsHistory = useRef();
  const speedingPointsLayerViewHistory = useRef();
  const speedingLines = useRef();
  const timeSlider = useRef();

  const timezoneGS = useMeasurements((state)=>state.timezone);
  const userTimezoneGS = useMeasurements((state)=>state.userTimezone); 
  const UserIdGuid = useUserStore((state)=>state.UserIdGuid);
  const SessionId = useUserStore((state)=>state.SessionId); 
  

  config.apiKey = process.env.REACT_APP_ARCGIS_API_KEY; 
 
 

  useEffect(()=>{  //runs at least once,  when the component mounts   
    
    console.log("use effect init"); 


    webmapHistory.current = new Map({
      // const webmap = new WebMap({
      basemap: "gray-vector" 
    });

    view.current = new MapView({
      map: webmapHistory.current,
      container:mapcontHistory.current, 
      center:[ 11.40189332293807, 50.9754784932554 ],
      zoom:4,
      timeZone:'UTC',
      popup: new Popup({
        dockEnabled: true,
        dockOptions: { 
          buttonEnabled: false, 
          breakpoint: false
        },
        position:'top-right'
      }),
    }) 

    let layerList = new LayerList({
      view: view.current
    });

    const layerListExpand = new Expand({
      id:'layerListExpand',
      expandIcon: "list",  
      view: view.current,
      content: layerList
    });

    let baseMapToggle = new BasemapToggle({
      view: view.current,
      nextBasemap:'satellite'
    }) 

    let search = new Search({
      view:view.current
    })

    const searchExpand = new Expand({
      id:'searchExpand',
      expandIcon: "search",  
      view: view.current,
      content: search
    }); 

    const legend = new Legend ({
      view: view.current
    });

    const legendExpand = new Expand({
      id:'legendExpand',
      expandIcon: "legend",  
      view: view.current,
      content: legend
    }); 

    timeSlider.current = new TimeSlider({ 
      playRate: 1000, 
      timeVisible: true,    
      // mode:'instant',
      layout:'wide'
    }); 

    view.current.ui.add(legendExpand, "top-right");
    view.current.ui.add(searchExpand, 'top-right'); 
    view.current.ui.add(baseMapToggle, 'top-right');
    view.current.ui.add(layerListExpand, 'top-left');     

    const speedingPointsRenderer = {
      type: "unique-value", 
      field: "status", 
      uniqueValueInfos: [
        {
        value: "Ongoing",
        label: "Ongoing",
          symbol: {
            type: "web-style",
            name: 'circle-5', 
            styleName: "Esri2DPointSymbolsStyle", 
          },
        }, 
        {
          value: "Started",
          label: "Started",
            symbol: {
              type: "web-style",
              name: 'SafetyCautionAlerts', 
              styleUrl: "https://cdn.arcgis.com/sharing/rest/content/items/36359a4a8f3143b6bf44d5688e007900/data"
            },
          }, 
          {
            value: "Ended",
            label: "Ended",
              symbol: {
                type: "web-style",
                name: 'SafetyCautionAlerts 1', 
                styleUrl: "https://cdn.arcgis.com/sharing/rest/content/items/36359a4a8f3143b6bf44d5688e007900/data"
              },
            }, 
      ]
    };
    const nameClassspeedingPoints = {
      labelPlacement: "above-center",
      labelExpressionInfo: {
        expression: 
        `
              var status = $feature.status; 
              var fn = $feature.source___Name +"  "+ $feature.source___speed +" "+  $feature.source___speedmeasure +" - "+ $feature.source___lastreportedtimelocal + " local time" ;
              if(status == 'Started'){
                fn = $feature.source___Name +"  "+ $feature.source___speed +" "+  $feature.source___speedmeasure +" - Started : "+ $feature.source___lastreportedtimelocal + " local time" ;
              }
              if(status == 'Ended'){
                fn = $feature.source___Name +"  "+  $feature.source___speed +" "+  $feature.source___speedmeasure +" - Ended : "+ $feature.source___lastreportedtimelocal + " local time" ;
              } 
              return fn 
              ` 
        
      }, 
      minScale: 573050
    };
    nameClassspeedingPoints.symbol = {
      type: "text", // autocasts as new TextSymbol()
      font: {
        size: 9, 
      },
      color: "white",
      haloColor: 'black',
      haloSize: 1,
      minScale: 57305
    };

    
    let yesterday = new Date(new Date().getTime() - (1 * 60 * 60 * 1000));
    let fullTimeExtentStart = yesterday.getTime(); 
    let fullTimeExtentEnd = new Date(); 
    console.log(' fullTimeExtentEnd ORIGINAL ', fullTimeExtentEnd);
    


    speedingPointsHistory.current = new FeatureLayer({
      // url: "https://mapsrv19.terra.gr/server/rest/services/Hosted/3d-fs-point-alerts-cumul-out/FeatureServer/0",
      url: "https://mapsrv19.terra.gr/server/rest/services/Hosted/TrackingPointSpeedingAlert/FeatureServer/0",
      title:'Speeding Alerts', 
      renderer:speedingPointsRenderer,
      labelingInfo: [nameClassspeedingPoints], 
      useViewTime:false,
      timeExtent:{
        start:fullTimeExtentStart,
        end:fullTimeExtentEnd
      },
      timeInfo: {
        trackIdField: "id",
        startField: "source___LastReportedTimeUTC", 
        endField: "source___LastReportedTimeUTC",  
        fullTimeExtent:{
          start:fullTimeExtentStart,
          end:fullTimeExtentEnd
        } 
      }, 
      opacity:0
    }); 

    view.current.whenLayerView(speedingPointsHistory.current)
    .then((layerView) => {
        speedingPointsLayerViewHistory.current = layerView;    
        setLayerLoaded(true)
      
      let nowDateStart = new Date(Date.now()); 
      let nowDateEnd = new Date(Date.now());
      let startDate = nowDateStart; 
      let endDate = nowDateEnd.setHours(nowDateEnd.getHours() - 24);  
      
      timeSlider.current.fullTimeExtent = {
        start:endDate,
        end:startDate
      };
      timeSlider.current.timeExtent = {
        start:endDate,
        end:startDate
      };
      timeSlider.current.stops = {
        interval: {
          value: 10,
          unit: "minutes"
        }
      }

    //   console.log(' speedingPoints on layerview ');
      
    }) //speedingPoints on layerview  

    webmapHistory.current.add(speedingPointsHistory.current,0);    

    timeSlider.current.watch('timeExtent', ()=>{   
      
      let start = timeSlider.current.timeExtent.start.toISOString().replace("T"," ").substring(0, 19);
      let end = timeSlider.current.timeExtent.end.toISOString().replace("T"," ").substring(0, 19);  
      if (timeSlider.current.timeZone !== 'UTC') {
        let startLocal = new Date(timeSlider.current.timeExtent.start);
        let endLocal = new Date(timeSlider.current.timeExtent.end); 
        start = startLocal.getFullYear() +"-"+ (startLocal.getMonth()+1) +"-"+ startLocal.getDate() +" "+ startLocal.getHours()+":"+startLocal.getMinutes()+":"+startLocal.getSeconds();
        end = endLocal.getFullYear() +"-"+ (endLocal.getMonth()+1) +"-"+ endLocal.getDate() +" "+ endLocal.getHours()+":"+endLocal.getMinutes()+":"+endLocal.getSeconds();
      }   

      speedingPointsHistory.current.featureEffect = new FeatureEffect({
        filter: new FeatureFilter({ 
          where: `timestamp > TIMESTAMP '${start}' AND timestamp < TIMESTAMP '${end}' AND name = 'PointSpeedIncident'`
        }), 
        excludedEffect: "opacity(0%)",
        includedEffect: "opacity(100%)"
      });  

      // speedingLines.current.featureEffect = new FeatureEffect({
      //   filter: new FeatureFilter({ 
      //     where: `timestamp > TIMESTAMP '${start}' AND timestamp < TIMESTAMP '${end}'`
      //   }), 
      //   excludedEffect: "opacity(0%)",
      //   includedEffect: "opacity(100%)"
      // });  

    })
    
    view.current.ui.add(timeSlider.current, 'bottom-leading' );


    
    
  },[]) //use effect , runs once


 
  
useEffect(()=>{ // runs when timezone changes 

  console.log('use effect timezone');
  

  let ts = 'UTC';
  let tsSting = "Last Reported (Global UTC Time)"; 

  if (timezoneGS === 'l') {
    ts = userTimezoneGS;
    tsSting = "Last Reported ("+userTimezoneGS+" Time)";
    view.current.timeZone = userTimezoneGS;  
    // setStartDateDP( dayjs.tz(startDateDP, userTimezoneGS) );  
    timeSlider.current.timeZone = userTimezoneGS;
  }
  else{
    view.current.timeZone = 'UTC';   
    // setStartDateDP( dayjs.utc(startDateDP) ); 
    timeSlider.current.timeZone = 'UTC';
  } 

},[timezoneGS])//timezoneGS useEffect 



useEffect(()=>{
  console.log(' TRYING TO QUEY OUT');
  
  
    if (layerLoaded === true && truck && startEndDateDP) { 
      console.log(' TRYING TO QUEY IN ', truck , startEndDateDP, layerLoaded);
        //console.log(' TRYING TO QUEY IN ',startEndDateDP, dayjs(startEndDateDP.endDateDP).unix() , dayjs(startEndDateDP.startDateDP).unix(), new Date(startEndDateDP.endDateDP));
        let startD = startEndDateDP.startDateDP.toISOString().replace('T', ' ').substring(0, 19);
        let endD = startEndDateDP.endDateDP.toISOString().replace('T', ' ').substring(0, 19); 
        //startDateDP , endDateDP <- older 
        let name = truck.attributes.name;        

        speedingPointsHistory.current.timeExtent.start = endD;
        speedingPointsHistory.current.timeExtent.end = startD;  
        speedingPointsHistory.current.definitionExpression = "source___Name = '"+name+"'";
        // speedingPoints.current.refresh();
        speedingPointsHistory.current.opacity = 1;      

        timeSlider.current.fullTimeExtent = {
          start:startEndDateDP.endDateDP,
          end:startEndDateDP.startDateDP
        };
        timeSlider.current.timeExtent = {
          start:startEndDateDP.endDateDP,
          end:startEndDateDP.startDateDP
        };
        
        const query = new Query();
        query.returnGeometry = true;
        query.outFields = [ "*" ];  
        query.orderByFields='source___LastReportedTimeUTC ASC';
        query.where = "source___Name = '"+name+"' AND  (source___LastReportedTimeUTC BETWEEN TIMESTAMP '"+endD+"' AND TIMESTAMP '"+startD+"' ) ";
        console.log('query.where ', query.where); 
        speedingPointsHistory.current.queryFeatures(query).then((results)=>{
          console.log('numResults ', results);  
          if (results.features.length === 0) { 
            setShowNoData(true)
          }
          else{
            setResultFeatures(results.features);
          }
        });


    }
}, [truck , startEndDateDP, layerLoaded])

useEffect(()=>{ 


  console.log('use effect charts');
  

  // {
  //   options: {
  //     chart: {
  //       id: 'apexchart-example'
  //     },
  //     xaxis: {
  //       categories: [0, 1]
  //     }
  //   },
  //   series: [{
  //     name: 'series-1',
  //     data: [5,6]
  //   }]}
  
  
    if (resultFeatures.length > 0) {  

      let xAxis= [];
      let dataSeries = [];

      
      let started =0;
      let ended =0;
      let ongoing =0;
        
      resultFeatures.forEach(element => {
        console.log(' gggg ', element.attributes );
        
        xAxis.push(new Date(element.attributes.source___LastReportedTimeLocal).toISOString().replace("T"," ").substring(0, 19));
        dataSeries.push(element.attributes.source___Speed);
        if (element.attributes.status === "Started") {
          started++;
        }
        else if (element.attributes.status ==="Ended"){
          ended++;
        }
        else{
          ongoing++;
        }


      });

 
        setChartState(
            {
          options: {
            chart: {
              id: 'speed-line'
            },
            xaxis: {
              categories: xAxis,
              min:xAxis[0],
              max:xAxis[(xAxis.length-1)]
            }
          },
          series: [{
            name: 'Speed',
            data: dataSeries
          }]}
        ) 


        setPieState
        ({ 
          series: [started, ended, ongoing],
          options: {
            chart: {
              height: 320,
              type: 'pie',
            },
            labels: ['Started', 'Ended', 'Ongoing'] 
          }
          
      });

    }// if  resultFeatures.length > 0
}, [resultFeatures]) 

 

  return (
    <div className="pageContainer"  >    
            <Grid container >  

            <Grid item xs={9} >   
            <div style={{position:'relative'}}>
              {
                showNoData &&
                <Alert  severity="warning" onClose={() => {setShowNoData(false)}} style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 999 }} >
                  No data found.
                </Alert>
              }
            </div>
            <div id="mapHistoryCont" className="mapcont"  ref={mapcontHistory}  ></div>   
            </Grid>
            <Grid item xs={3} >   
              <div>
              {
                  resultFeatures.length > 0 &&
                  <div>
                      <div>{resultFeatures.length} Alerts</div>
                      <div>Condition : max speed <span> </span> {resultFeatures[0].attributes.source___max_allowed_speed}</div>
                      <div>
                        <Chart options={chartState.options} series={chartState.series} type="line"  height={320} /> 
                      </div>
                      <div>
                        <Chart  series={pieState.series} options={pieState.options} type="pie" height={320} />
                      </div>
                  </div>

              }
              </div>
            </Grid>
            </Grid>
    </div>
  )
}

export default memo(HistoryMap)

