import './App.css'; 
import {BrowserRouter as Router ,Route, Routes} from 'react-router-dom'
import {useEffect} from "react";
import { type } from '@testing-library/user-event/dist/type';
import MapPage from './pages/MapPage' 
import History from './pages/History' 
import PageNotFound from './pages/PageNotFound' 
import Header from './elements/Header'
import Footer from './elements/Footer' 

function App() {
 

  useEffect(()=>{ 
 

  }, []) //use effect


  return (
    <div className="App">
        <Router>   
            <Header  />  
                <Routes>
                  <Route path='/' element={<MapPage/>} />    
                  <Route path='live' element={<MapPage/>} />   
                  <Route path='/history/' element={<History/>} >    
                    <Route path=':truckNameParam?' element={<History/>} />    
                  </Route>
                  <Route path='*' element={<PageNotFound/>} />   
                </Routes>    
            <Footer />    
        </Router>  
    </div>
  );
}

export default App;

