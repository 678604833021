import { memo, useEffect, useMemo, useState } from "react";  
import { Box , Button, Drawer, Grid, Checkbox } from "@mui/material";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ZoomInIcon from '@mui/icons-material/ZoomIn'; 
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'; 
import { useMeasurements } from "../common/store/measurements";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';   
import AdjustIcon from '@mui/icons-material/Adjust';
import FilterTiltShiftIcon from '@mui/icons-material/FilterTiltShift';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from 'react-router-dom';

// function TruckList ({trucks, trucksNum,newData, mapActions, dispatch, featureAlerts }){  
function TruckList ({trucks, trucksNum, mapActions, dispatch, featureAlerts }){  

    const navigate = useNavigate(); 
    

    const [truckName , setTruckName] = useState('');       
    const [loading , setLoading] = useState(true);       
    const [filteredTrucks , setFilteredTrucks] = useState([]);       
    // const [orignalTrucks , setOrignalTrucks] = useState([]);       
    // const timezoneGS = useMeasurements((state)=>state.timezone); 


    const zoomToFeature = (e,name) => {
        mapActions({action:'zoomToFeature', feature: e, name : name});
    }

    const showAllFeaturesOnMap = () => {
        mapActions({action:'showAllFeatures'});
    }

    const followFeature = (event,feature) => { 
        // let newarray = orignalTrucks.slice().map((e)=>{ 
        let newarray = filteredTrucks.slice().map((e)=>{ 
            if (e.attributes.name === feature.attributes.name) { 
                e.attributes.follow = event.target.checked; 
                return e;
            }
            else{
                return e;
            }
        }) ;
        // setOrignalTrucks(newarray);
        setFilteredTrucks(newarray);
        dispatch({action:'followFeature', event, feature })
    } 

    const unfollowAllFeatures = () => {

        // let newarray = orignalTrucks.slice();
        // newarray.forEach(el => {
        //     el.attributes.follow = false;
        // });
        // setOrignalTrucks(newarray);

        let newarrayCopy = filteredTrucks.slice();
        newarrayCopy.forEach(el => {
            el.attributes.follow = false;
        });
        setFilteredTrucks(newarrayCopy); 

        dispatch({action:'unfollowAllFeatures' })
    }

    const aletsOfFeature = (e) => {
        console.log(' aletsOfFeature');
        featureAlerts(e);
   }

   
    const historyOfFeature = (e) => { 
        // featureAlerts(e);
        navigate("/history/"+e.attributes.name);
    }
   
    const searchListNameChange = (e)=>{ 
        setTimeout(() => {   
          setTruckName(e.target.value)
        }, 200); 
    } //searchListNameChange 


    useEffect(()=>{ //trucks list
        console.log('useEffect trucks');
        
        if (trucks?.length > 0 ) {
            setFilteredTrucks(trucks);
            // setOrignalTrucks(trucks);
            setLoading(false);
        }
    }, [trucks])  

    useMemo(() => { // truck name changes

        // let a = orignalTrucks.filter((el)=>{
        //     return el.attributes.name.toLowerCase().includes(truckName.toLowerCase())
        // }) ;
        // setFilteredTrucks(a);

        let newarray = filteredTrucks.slice().map((e)=>{ 
            if (e.attributes.name.toLowerCase().includes(truckName.toLowerCase())) { 
                e.attributes.display = 1; 
                return e;
            }
            else{
                e.attributes.display = 0; 
                return e;
            }
        }) ;
        setFilteredTrucks(newarray);
        
   } , [truckName]);

    // useMemo(() => { //newData

    //     if (newData) {

    //         console.log('usememo newdata ', newData);

    //         let lastrep ;
    //         if (timezoneGS === 'l') {
    //             lastrep = new Date(newData.attributes.LastReportedTimeUTC).toLocaleString();
    //         }
    //         else{
    //             lastrep = new Date(newData.attributes.LastReportedTimeUTC).toISOString().replace("T"," ").substring(0, 19);
    //         } 

    //         let a = filteredTrucks.filter((e)=>{
    //             if(e.attributes.imei === newData.attributes.imei){   
    //                 e.attributes.speed = newData.attributes.Speed;
    //                 e.attributes.address = newData.attributes.Address ; 
    //                 e.attributes.lastupdate = lastrep; 
    //                 return e;
    //             }
    //             else{
    //                 return e;
    //             }
    //         });
    //         setFilteredTrucks(a);

    //         // let b = orignalTrucks.filter((e)=>{
    //         //     if(e.attributes.imei === newData.attributes.imei){   
    //         //         e.attributes.speed = newData.attributes.Speed;
    //         //         e.attributes.address = newData.attributes.Address ; 
    //         //         e.attributes.lastupdate = lastrep; 
    //         //         return e;
    //         //     }
    //         //     else{
    //         //         return e;
    //         //     }
    //         // }); 
    //         // setOrignalTrucks(b);
    //     }
        
    // } , [newData]);

    // useMemo(()=>{ // runs when timezone changes 

    //     let newarray = filteredTrucks.slice().map((e)=>{ 
    //         let lastrep ;
    //         if (timezoneGS === 'l') {
    //             lastrep = new Date(e.attributes.lastreportedtimeutc).toLocaleString();
    //         }
    //         else{
    //             lastrep = new Date(e.attributes.lastreportedtimeutc).toISOString().replace("T"," ").substring(0, 19);
    //         }
    //         e.attributes.lastupdate = lastrep; 
    //         return e; 

    //     }) ;

    //     setFilteredTrucks(newarray); 

    //     // let newarrayOriginal = orignalTrucks.slice().map((e)=>{ 
    //     //     let lastrep ;
    //     //     if (timezoneGS === 'l') {
    //     //         lastrep = new Date(e.attributes.lastreportedtimeutc).toLocaleString();
    //     //     }
    //     //     else{
    //     //         lastrep = new Date(e.attributes.lastreportedtimeutc).toISOString().replace("T"," ").substring(0, 19);
    //     //     }
    //     //     e.attributes.lastupdate = lastrep; 
    //     //     return e; 

    //     // }) ;

    //     // setOrignalTrucks(newarrayOriginal); 


    // },[timezoneGS])//timezoneGS useEffect 

    
    return(
        <div>
            <Box  style={{height:'calc(50vh)'}}    sx={{overflowX:'clip', overflowY:'scroll', border:'1px solid'}}> 
            <div id="listHandle" style={{cursor:'e-resize'}}> <span className="listTopIcon"> < LocalShippingIcon /> </span>Trucks </div>
            <input 
              id="search-list-name" 
              type="text"
              placeholder="Search by Name"  
              onChange={(e)=>{searchListNameChange(e)}} 
              style={{margin:'5px auto 0 auto', width:'100%'}} 
            />

            <Box sx={{fontSize:'12px'}}> 
                  
                  <Grid container  > 
                    <Grid item xs={6} style={{textAlign:'right', marginTop:'5px'}} >
                        {trucksNum} Trucks
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}   >
                      <button  
                        onClick={() => showAllFeaturesOnMap()}
                        style={{ cursor:'pointer', border:'1px solid white',  backgroundColor:'white'}} 
                      >
                        <ZoomOutMapIcon size="small" />
                      </button>
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'right'}}>  
                        <button  
                        onClick={() => unfollowAllFeatures()}
                          style={{cursor:'pointer', border:'1px solid white',  backgroundColor:'white'}} 
                        >  
                          <FilterTiltShiftIcon />
                        </button>
                    </Grid>
                  </Grid> 
            </Box>
            {
                filteredTrucks && filteredTrucks.length>0 ? 
                // <div  style={{ height:'calc(100vh - 134px)',overflowX:'scroll'}}>
                <div  style={{ overflowY:'scroll'}}>
                {
                    filteredTrucks.map((e)=>(
                    <Box key = {e.attributes.uid}  className= { 'listBox ' + (e.attributes.display === 1 ? 'showElement' : 'hideElement') }  >  
                        <div className="listItemTitle" >
                            
                            <Grid container  >
                            <Grid item xs={4} sx={{fontSize:'12px', fontWeight:'bold', paddingTop:'10px'}}> 
                                {e.attributes.name} 
                            </Grid>


                            <Grid item xs={2}   >
                                <button 
                                onClick={() => historyOfFeature(e)}
                                style={{marginTop:'5px', cursor:'pointer',  border:'0'}} 
                                >
                                <HistoryIcon size="small" />
                                </button>
                            </Grid>

                            <Grid item xs={2}   >
                                <button 
                                onClick={() => aletsOfFeature(e)}
                                style={{marginTop:'5px', cursor:'pointer',  border:'0'}} 
                                >
                                <CrisisAlertIcon size="small" />
                                </button>
                            </Grid>


                            <Grid item xs={2}   >
                                <button 
                                onClick={() => zoomToFeature(e, e.attributes.name)}
                                style={{marginTop:'5px', cursor:'pointer', border:'0'}} 
                                >
                                <ZoomInIcon size="small" />
                                </button>
                            </Grid>

                            
                            <Grid item xs={2} >   
                                <Checkbox 
                                    onClick={(event) => {followFeature(event, e);}}
                                    size="small"
                                    checked={e?.attributes?.follow}
                                    icon={<FilterTiltShiftIcon/>} 
                                    checkedIcon={<AdjustIcon />}
                                    sx={{marginRight:'0px', paddingRight:'0px'}}
                                />  
                            </Grid>
                            </Grid>

                        </div>
                        {/* <div className="listItem">
                            <b>{e.attributes.address}  </b>
                        </div>  
                        <div className="listItem">
                            Speed : <b style={{marginLeft:'5px'}}> {e.attributes.speed} {e.attributes.speedmeasure} </b>
                        </div>
                        <div className="listItem">
                            Reported : <b style={{marginLeft:'5px'}}> {e.attributes.lastupdate}  </b>
                        </div>   */}

                    </Box>
                    )) 
                }
                </div>
                :
                <div>
                    <Box sx={{textAlign:'center', margin:'5px auto', width:'100%'}}> 
                    <div>
                        {
                            loading ? 'Loading' : 'No names found' 
                        }
                        
                    </div>
                    </Box>
                </div>
            }
            </Box>
        </div>
    )
}

export default memo(TruckList);