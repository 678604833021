//rafce 
import * as React from 'react';   
import Box from '@mui/material/Box'; 
import Grid from '@mui/material/Grid';  
import { Link } from 'react-router-dom';

const PageNotFound = () => { 


  return (
    <div className="pageContainer"  >  
        
        <Grid container  >
            <Grid item xs={12} > 
              <h1>Page Not Found</h1>
              <div>Go to <Link to="live">Live page</Link> </div>
            </Grid> 
        </Grid>
    </div>
  )
}

export default PageNotFound
