import axios from "axios";
import { memo, useEffect, useMemo, useState } from "react";  
import { Box , Button, Drawer, Grid, Checkbox } from "@mui/material";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ZoomInIcon from '@mui/icons-material/ZoomIn';  
import { useMeasurements } from "../common/store/measurements";  
import PieChartIcon from '@mui/icons-material/PieChart';
import Chart from 'react-apexcharts'


function AlertsPie (){  

  const [withErrors , setWithErrors] = useState(0); 
  const [speed , setSpeed] = useState(0); 
  const [route , setRoute] = useState(0);  
    
  const [pieState , setPieState] = useState({ 
    series: [0],
    options: {
      chart: {
        height: 340,
        type: 'pie',
      },
      labels: ['Loading'] 
    }
    
  });  
  
  
  const [bar , setbar] = useState(
    {
      options: {
        chart: {
          id: "basic-bar",
          height: 200,
          type: 'bar',
        },
        xaxis: {
          categories: ['Loading']
        }
      },
      series: [
        {
          name: "Loading",
          data: [0]
        }
      ]
    }
);  

  useEffect(()=>{ 
    getData();
  }, [])  

  const getData = async () =>{
    let url = 'https://mapsrv19.terra.gr/server/rest/services/Hosted/TrackingPointSpeedingAlert/FeatureServer/0/query'; 
    let nowDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    let yesterday = nowDate.getTime();
    console.log('alert pie  nowDate ', nowDate , ' - yesterday ', yesterday ); 
    let response;  
    let where = "( name = 'PointSpeedIncident' OR name = 'PossibleHijacking' ) AND source___LastReportedTimeUTC > "+ yesterday;  

    try {

          response = await axios.get(
              url, 
              {
                  headers: { 
                      "Content-Type": "application/json",  
                  }, 
                  withCredentials: false,
                  params:{
                      where:where,
                      geometryType:'esriGeometryPoint' , 
                      units:'esriSRUnit_Meter',
                      outFields:'*',  
                      f:'json'
                  }
              },
          );//get  

          console.log('alert pie new alerts flag response ', response);  
          let results = response.data.features;
          console.log(' alert pie new alerts flag results ', results);
          

          let desired_output = (results) => {
              let unique_values = results
                  .map((item) => item.attributes.source___Name)
                  .filter(
                      (value, index, current_value) => current_value.indexOf(value) === index
                  );
              return unique_values;
          };
        let a  = desired_output(results)
        console.log('alert pie RESULTS ', a.length);
        
        let alen = Number(a.length);  
        
        

          setPieState
          ({ 
            series: [alen, 1098],
            options: {
              chart: {
                height: 340,
                type: 'pie',
              },
              labels: ['trucks with alerts', 'all trucks'] 
            }
            
        }); 
        setWithErrors(a.length); 

        let speeding = 0;   let deroute = 0; 

        results.forEach(element => {
          if (element.attributes.name === "PointSpeedIncident") {
            speeding++;
          }
          else{
            deroute++;
          }
        });

        setbar(
          {
            options: {
              chart: {
                id: "basic-bar",
                height: 200,
                type: 'bar',
              },
              xaxis: {
                categories: ['Speeding', 'De-routing']
              }
            },
            series: [
              {
                name: "Speeding, De-routing",
                data: [speeding,deroute]
              }
            ]
          }
      );  

      setSpeed(speeding); setRoute(deroute);
      

        }//try
        catch (error) {
          console.log('alert pie error getUnits  ',  error); 
      }//catch
  }//getData
 



    
    return(  
      <Box  style={{height:'calc(50vh)'}}    sx={{overflowX:'clip', overflowY:'scroll', border:'1px solid'}}> 
        <div id="listHandle" style={{cursor:'e-resize'}}> <span className="listTopIcon"> < PieChartIcon /> </span>Alerts, Last 24 hrs </div> 
        <Chart  series={pieState.series} options={pieState.options} type="pie" height={340} /> 
        <small>{withErrors} / 1098 trucks</small>   

        <div style={{backgroundColor:'#f3f3f3', borderTop:'1px solid black'}}>
          <Chart options={bar.options} series={bar.series} type="bar" height={200} /> 
          <small>{speed} Speeding | {route} De-routed</small>
        </div>  
      </Box> 

    )
}

export default memo(AlertsPie);


 