import axios from "axios";
import { memo, useEffect, useMemo, useState } from "react";  
import { Box , Button, Drawer, Grid, Checkbox } from "@mui/material";
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ZoomInIcon from '@mui/icons-material/ZoomIn';  
import { useMeasurements } from "../common/store/measurements";  

function AlertsList ({ mapActions , flag, alertsLivePerTruck }){  
    
    const [liveOrTruckAlerts , setLiveOrTruckAlerts] = useState('l');    
    const [alertCategory, setAlertCategory] = useState('all');     
    const [liveAlerts, setLiveAlerts] = useState([]);         
    const [liveAlertsCopy, setLiveAlertsCopy] = useState([]);      
    const [truckAlerts , setTruckAlerts] = useState([]);       
    const [howManyNewAlertsLive , setHowManyNewAlertsLive] = useState(5);         
    const timezoneGS = useMeasurements((state)=>state.timezone); 
    
    const zoomToFeature = (e,name) => {
        mapActions({action:'zoomToFeature', feature: e, name : name});
    }
    const showAllFeaturesOnMap = () => {
        mapActions({action:'showAllFeatures'});
    }

    const handleAlertCategoryChange = (e) => {
        setAlertCategory(e.target.value);
        
        if (e.target.value === 'all') { 
            let alerts = liveAlerts.slice();
            setLiveAlertsCopy(alerts);  
        }
        else{ 
            let filtered = liveAlerts.filter((el)=>{
                return el.attributes.name === e.target.value
            });
            setLiveAlertsCopy(filtered);
        }
    }

    const handleLoadMoreLiveAlerts = ()=>{ 
      setTimeout(() => {   
        let a = howManyNewAlertsLive + 5;  
        setHowManyNewAlertsLive(a); 
      }, 300);  
    }


  useEffect(()=>{ // flag
      
    getGeneralAlerts()

  }, [flag])  

  const getGeneralAlerts = async () =>{
    if (flag && flag  > 0 ) {
        console.log(' getGeneralAlerts data ', flag, alertsLivePerTruck);
        
        let url = 'https://mapsrv19.terra.gr/server/rest/services/Hosted/TrackingPointSpeedingAlert/FeatureServer/0/query'; 

        
        let nowDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
        let yesterday = nowDate.getTime();
        console.log('nowDate ', nowDate , ' - yesterday ', yesterday ); 


        let nowDate4 = new Date(new Date().getTime() - (4 * 60 * 60 * 1000));
        let yesterday4 = nowDate4.getTime();
        console.log('nowDate ', nowDate4 , ' - yesterday4 ', yesterday4 ); 

        let response;  
        let orderByFields = "source___LastReportedTimeUTC DESC"; 
        let where = "( name = 'PointSpeedIncident' OR name = 'PossibleHijacking' ) AND source___LastReportedTimeUTC > "+ yesterday;  
        let num = howManyNewAlertsLive;
        if (alertsLivePerTruck !== '') { 
            where ="( name = 'PointSpeedIncident' OR name = 'PossibleHijacking' ) AND source___LastReportedTimeUTC > "+ yesterday + "AND source___Name = '"+alertsLivePerTruck+"' "; 
            num = 10;
        }  

        try {

            response = await axios.get(
                url, 
                {
                    headers: { 
                        "Content-Type": "application/json",  
                    }, 
                    withCredentials: false,
                    params:{
                        where:where,
                        geometryType:'esriGeometryPoint' , 
                        units:'esriSRUnit_Meter',
                        outFields:'*', 
                        orderByFields:orderByFields,
                        f:'json',
                        resultRecordCount:num
                    }
                },
            );//get  

            console.log('new alerts flag response ', response);  
            let results = response.data.features;
            console.log(' new alerts flag results ', results);
            
            results.forEach(element => {
              let lastr;
              if (timezoneGS === 'l') {
                lastr = new Date(element.attributes.source___LastReportedTimeUTC).toLocaleString();
              }
              else{
                lastr = new Date(element.attributes.source___LastReportedTimeUTC).toISOString().replace("T"," ").substring(0, 19);
              }

              element.attributes.lastupdate = lastr
            });
            results.sort((a,b)=>{ 
              if ( a.attributes.source___LastReportedTimeUTC < b.attributes.source___LastReportedTimeUTC ){
                return 1;
              }
              if ( a.attributes.source___LastReportedTimeUTC > b.attributes.source___LastReportedTimeUTC ){
                return -1;
              }
              return 0;
            })
            if (alertsLivePerTruck !== '') {
              setTruckAlerts(results);
            }
            else{
              // let dateFilteredResults = results.filter((el)=>el.attributes.source___LastReportedTimeUTC)
              setLiveAlerts(results);
              console.log(' new alerts flag in else ', results);
              console.log(' new alerts flag alertCategory ', alertCategory);
              if (alertCategory !== 'all') {
                let filtered = results.filter((el)=>{
                  return el.attributes.name === alertCategory;
                });
                setLiveAlertsCopy(filtered);
              }
              else{
                console.log(' new alerts flag in small else ', results);
                setLiveAlertsCopy(results);
              } 
            }
        }//try
        catch (error) {
            console.log('error getUnits  ',  error); 
        }

    }
  }//getGeneralAlerts

  useMemo( async ()=>{ // howManyNewAlertsLive
        
      
          let url = 'https://mapsrv19.terra.gr/server/rest/services/Hosted/TrackingPointSpeedingAlert/FeatureServer/0/query'; 

          
          let nowDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
          let yesterday = nowDate.getTime();
          console.log('nowDate ', nowDate , ' - yesterday ', yesterday ); 

          let response;  
          let orderByFields = "source___LastReportedTimeUTC DESC"; 
          let where = "( name = 'PointSpeedIncident' OR name = 'PossibleHijacking' ) AND source___LastReportedTimeUTC > "+ yesterday;  
          let num = howManyNewAlertsLive; 

          try {

              response = await axios.get(
                  url, 
                  {
                      headers: { 
                          "Content-Type": "application/json",  
                      }, 
                      withCredentials: false,
                      params:{
                          where:where,
                          geometryType:'esriGeometryPoint' , 
                          units:'esriSRUnit_Meter',
                          outFields:'*', 
                          orderByFields:orderByFields,
                          f:'json',
                          resultRecordCount:num
                      }
                  },
              );//get  

              console.log('new alerts  response ', response);  
              let results = response.data.features;
              results.forEach(element => {
                let lastr;
                if (timezoneGS === 'l') {
                  lastr = new Date(element.attributes.source___LastReportedTimeUTC).toLocaleString();
                }
                else{
                  lastr = new Date(element.attributes.source___LastReportedTimeUTC).toISOString().replace("T"," ").substring(0, 19);
                }

                element.attributes.lastupdate = lastr
              });
              results.sort((a,b)=>{ 
                if ( a.attributes.source___LastReportedTimeUTC < b.attributes.source___LastReportedTimeUTC ){
                  return 1;
                }
                if ( a.attributes.source___LastReportedTimeUTC > b.attributes.source___LastReportedTimeUTC ){
                  return -1;
                }
                return 0;
              }) 
              setLiveAlerts(results);
              console.log(' new alerts flag alertCategory howManyNewAlertsLive', alertCategory);
              
              if (alertCategory !== 'all') {
                let filtered = results.filter((el)=>{
                  return el.attributes.name === alertCategory;
                });
                setLiveAlertsCopy(filtered);
              }
              else{
                setLiveAlertsCopy(results);
              }  
          }//try
          catch (error) {
              console.log('error getUnits  ',  error); 
          } 

  }, [howManyNewAlertsLive])  


  useMemo (async()=>{ // alertsLivePerTruck

    if (alertsLivePerTruck && alertsLivePerTruck!== '') { 
    
      console.log(' new alerts flag alertCategory getAlertsLivePerTruck runs');
      let url = 'https://mapsrv19.terra.gr/server/rest/services/Hosted/TrackingPointSpeedingAlert/FeatureServer/0/query'; 
             
      let nowDate = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      let yesterday = nowDate.getTime();
      console.log('nowDate ', nowDate , ' - yesterday ', yesterday ); 

      let response;  
      let orderByFields = "source___LastReportedTimeUTC DESC";    
      let where ="( name = 'PointSpeedIncident' OR name = 'PossibleHijacking' ) AND source___LastReportedTimeUTC > "+ yesterday + "AND source___Name = '"+alertsLivePerTruck+"' "; 
      let num = 10; 

      try {

          response = await axios.get(
              url, 
              {
                  headers: { 
                      "Content-Type": "application/json",  
                  }, 
                  withCredentials: false,
                  params:{
                      where:where,
                      geometryType:'esriGeometryPoint' , 
                      units:'esriSRUnit_Meter',
                      outFields:'*', 
                      orderByFields:orderByFields,
                      f:'json',
                      resultRecordCount:num
                  }
              },
          );//get  

          console.log('new alerts  response ', response);  
          let results = response.data.features;
          results.forEach(element => {
            let lastr;
            if (timezoneGS === 'l') {
              lastr = new Date(element.attributes.source___LastReportedTimeUTC).toLocaleString();
            }
            else{
              lastr = new Date(element.attributes.source___LastReportedTimeUTC).toISOString().replace("T"," ").substring(0, 19);
            }

            element.attributes.lastupdate = lastr
          });
          results.sort((a,b)=>{ 
            if ( a.attributes.source___LastReportedTimeUTC < b.attributes.source___LastReportedTimeUTC ){
              return 1;
            }
            if ( a.attributes.source___LastReportedTimeUTC > b.attributes.source___LastReportedTimeUTC ){
              return -1;
            }
            return 0;
          })
          setTruckAlerts(results); 
          setLiveOrTruckAlerts('t');
      }//try
      catch (error) {
          console.log('error getUnits  ',  error); 
      }
    }

  }, [alertsLivePerTruck])  



    
    return(  
        <Box className="pageContainer" sx={{overflowX:'clip', overflowY:'hidden', border:'1px solid'}}> 
            <div id="alertsHandle" style={{cursor:'e-resize'}}> <span className="listTopIcon"><CrisisAlertIcon/> </span> Alerts </div> 
            <div style={{margin:'5px auto'}}>
                <button style={{border:'1px solid', width:'50%', display:'inline-block', textAlign:'center' , backgroundColor: ((liveOrTruckAlerts !== 'l') ? 'white' : 'lightgrey') }} onClick={()=>{setLiveOrTruckAlerts('l');showAllFeaturesOnMap();}}   >Last 24hrs </button>
                <button style={{border:'1px solid', width:'50%', display:'inline-block', textAlign:'center' , backgroundColor: ((liveOrTruckAlerts !== 't') ? 'white' : 'lightgrey') }} onClick={()=>{setLiveOrTruckAlerts('t')}}  >By Truck</button> 
            </div>
                

            <div  style={{display: (liveOrTruckAlerts === 'l' ? 'block' : 'none') }}>
                <select 
                    onChange={handleAlertCategoryChange}
                    value={alertCategory}
                    style={{width:'100%', margin:'0 auto 5px auto', textAlign:'center'}}
                >
                    <option value="all">All</option>
                    <option value="PointSpeedIncident">Speeding</option>
                    <option value="PossibleHijacking">De-route</option>
                </select>

                {  
                liveAlertsCopy && liveAlertsCopy.length>0 ?  
                <div  style={{ height:'calc(100vh - 200px)',overflowX:'scroll'}}>
                {
                    liveAlertsCopy.map((e)=>(
                    <Box key = {e.attributes.source___Uid} className='listBox'> 
                        <div className="listItemTitle">
                            
                            <Grid container  >
                            <Grid item xs={10} sx={{fontSize:'12px', fontWeight:'bold', paddingTop:'10px'}}> 
                                {e.attributes.name === "PointSpeedIncident" ? 'Speeding' : 'De-routed'} 
                                {' : '+e.attributes.source___Name}
                            </Grid>
                            <Grid item xs={2}   >
                                <button 
                                onClick={() => zoomToFeature(e, e.attributes.source___Name)}
                                style={{marginTop:'5px', cursor:'pointer', backgroundColor:'lightgray', border:'1px solid gray'}} 
                                >
                                <ZoomInIcon size="small" />
                                </button>
                            </Grid> 
                            </Grid>

                        </div>
                        <div className="listItem">
                            <b>{e.attributes.description} </b>
                        </div>
                        <div className="listItem">
                            {e.attributes.name === "PointSpeedIncident" ? 'Speed : ' : 'Address : '} 
                            <b style={{marginLeft:'5px'}}> {e.attributes.name === "PointSpeedIncident" ?  e.attributes.source___Speed +' '+ e.attributes.source___SpeedMeasure : e.attributes.source___Address} </b>
                        </div>   
                        <div className="listItem">
                            Reported : <b style={{marginLeft:'5px'}}>  {' '+e.attributes.lastupdate}  </b>
                        </div>  

                    </Box>
                    )) 
                }
                </div>
                :
                <div>
                    <Box sx={{textAlign:'center', margin:'5px auto', width:'100%'}}> 
                    <div> 
                        {liveAlertsCopy.length === 0 ? 'no alerts found ': 'Loading'}
                    </div>
                    </Box>
                </div>

                } 
                <div style={{margin:'10px auto'}}>
                <button  
                    style={{ width:'100%'}}
                    onClick={handleLoadMoreLiveAlerts} 
                    disabled={howManyNewAlertsLive>=40}
                >
                    {howManyNewAlertsLive>=40 ? 'For more, go to History' : 'Load More'}
                </button>
                </div>
            </div>


            <div  style={{display: (liveOrTruckAlerts === 't' ? 'block' : 'none') }}>
                <div style={{margin:'5px auto'}}>Latest Alerts of Truck {alertsLivePerTruck} </div>
                {  
                  truckAlerts && truckAlerts.length>0 && alertsLivePerTruck !='' ?  
                  <div  style={{ height:'calc(100vh - 134px)',overflowX:'scroll'}}>
                  {
                    truckAlerts.map((e)=>(
                      <Box key = {e.imei} className='listBox'> 
                          <div className="listItemTitle">
                            
                            <Grid container  >
                              <Grid item xs={10} sx={{fontSize:'12px', fontWeight:'bold', paddingTop:'10px'}}> 
                                {e.attributes.name === "PointSpeedIncident" ? e.attributes.source___Name+' Speeding' : e.attributes.source___Name+' De-routed'}  
                              </Grid>
                              <Grid item xs={2}   >
                                <button 
                                  onClick={() => zoomToFeature(e, e.attributes.source___Name)}
                                  style={{marginTop:'5px', cursor:'pointer', backgroundColor:'lightgray', border:'1px solid gray'}} 
                                >
                                  <ZoomInIcon size="small" />
                                </button>
                              </Grid> 
                            </Grid>

                          </div>
                          <div className="listItem">
                            <b>{e.attributes.description} </b>
                          </div>
                          <div className="listItem">
                            {e.attributes.name === "PointSpeedIncident" ? 'Speed : ' : 'Address : '} 
                            <b style={{marginLeft:'5px'}}> {e.attributes.name === "PointSpeedIncident" ?  e.attributes.source___Speed +' '+ e.attributes.source___SpeedMeasure : e.attributes.source___Address} </b>
                          </div>   
                          <div className="listItem">
                            Reported : <b style={{marginLeft:'5px'}}> {' '+e.attributes.lastupdate}  </b>
                          </div>   

                      </Box>
                    )) 
                  }
                  </div>
                  :
                  <div>
                    <Box sx={{textAlign:'center', margin:'5px auto', width:'100%'}}> 
                      <div>
                        {
                          alertsLivePerTruck === '' 
                          ? 
                          'Select a Truck from the Truck List' 
                          : 
                          truckAlerts.length === 0 ? 'no alerts found ': 'Loading'
                        }  
                      </div>
                    </Box>
                  </div>

                }  
            </div>  
        </Box> 

    )
}

export default memo(AlertsList);


 