import { getUnits } from "../api/unitsAPI"; 

const getUnitsData = async (UserIdGuid, SessionId) => { 
    let data = await getUnits(UserIdGuid , SessionId); 
    let results;
    if (data?.status === 200 && data?.data?.features?.length > 0 ) {    
      results = data.data.features;
      return results;
    } 
    else{
        return [];
    }
  };


  export { getUnitsData };