import axios from "axios";
import { configUrls } from "../../config/base"; 

async function getUnits(UserIdGuid, SessionId) { 
 
    // const url = `${configUrls.basicUrl}/${configUrls.servicePath}/${configUrls.hosted}/${configUrls.lastpositionFeatureServer}/${configUrls.featureLayers.lastpositionFsOut}/query`;   
    // let url = 'https://mapsrv19.terra.gr/server/rest/services/Hosted/3d_tracking_lastposition_fs_out/FeatureServer/0/query' ;
    let url = 'https://mapsrv19.terra.gr/server/rest/services/Tracking-LastPosition/FeatureServer/0/query';
    console.log('api url ', url);

    let response; 

    try {

        response = await axios.get(
            url, 
            {
                headers: { 
                    "Content-Type": "application/json",  
                }, 
                withCredentials: false,
                params:{
                    where:'1=1',
                    geometryType:'esriGeometryPoint' , 
                    units:'esriSRUnit_Meter',
                    outFields:'*',
                    returnGeometry:true,
                    returnDistinctValues:false, 
                    f:'pjson'
                }
            },
        );//get 


        console.log('API response ', response);  

        response.data.features.forEach(element => {
            element.attributes.follow = false;
            element.attributes.history = false;
            element.attributes.lastupdate = element.attributes.lastreportedtimeutc;
            element.attributes.display = 1;
        });
 
         

        return response;
    }//try 
    catch (error) {
        console.log('error getUnits  ',  error);
        return error;
    }
       
}



export { getUnits };
