//rafce 
import Map from "@arcgis/core/Map.js"; 
import config from "@arcgis/core/config.js";
import MapView from "@arcgis/core/views/MapView.js"; 
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle.js";
import Search from "@arcgis/core/widgets/Search.js"; 
import Legend  from "@arcgis/core/widgets/Legend.js"; 
import Expand from "@arcgis/core/widgets/Expand.js"; 
import LayerList from "@arcgis/core/widgets/LayerList.js"; 
import StreamLayer from "@arcgis/core/layers/StreamLayer.js";  
import { Box , Button, Drawer, Checkbox } from "@mui/material"; 
import {useEffect, useState, useRef, useMemo, useCallback, useReducer } from "react"; 
import {getUnits} from '../common/api/unitsAPI.js' 
import { useUserStore } from "../common/store/user";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop'; 
import Grid from '@mui/material/Grid'; 
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect.js";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter.js";
import Extent from "@arcgis/core/geometry/Extent.js"; 
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import TimeSlider from "@arcgis/core/widgets/TimeSlider.js";
import { useMeasurements } from "../common/store/measurements.js";
import Popup from "@arcgis/core/widgets/Popup.js";
import TruckList from "../components/TrackList.js";
import AlertsList from "../components/AlertsList.js"; 
import { useParams } from 'react-router-dom';
import { useTrucks } from "../common/store/trucks.js";
import { getUnitsData } from "../common/trucks/getTrucks.js";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';  
import DateRangeIcon from '@mui/icons-material/DateRange';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from "moment-timezone";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs' ;
import HistoryMap from "../components/HistoryMap.js"; 
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const History = () => {    

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [allFeatures, setAllFeatures] = useState([]);    
  const [truckName , setTruckName] = useState('');        
  const [selectedTruck , setSelectedTruck] = useState('');        
  const [selectedTruckFromUrl ,setSelectedTruckFromUrl] = useState('');         
  const {truckNameParam} = useParams();  
  // const [startDateDP, setStartDateDP] = useState(new Date().toISOString().replace("T"," ").substring(0, 19)); 
  const [startDateDP, setStartDateDP] = useState( dayjs(new Date()) ); 
  const [endDateDP, setEndDateDP] = useState( dayjs( new Date().setHours(new Date().getHours() - 24 ) ) ); 
  const [startEndDateDP, setStartEndDateDP] = useState(''); 
  const [tzdp, setTzdp] = useState( 'utc' ); 


  const timezoneGS = useMeasurements((state)=>state.timezone);
  const userTimezoneGS = useMeasurements((state)=>state.userTimezone); 
  const UserIdGuid = useUserStore((state)=>state.UserIdGuid);
  const SessionId = useUserStore((state)=>state.SessionId); 
  const trucksListGS = useTrucks((state)=>state.trucksList);  
  const setTrucksListGS = useTrucks((state)=>state.setTrucksList);   

 
  

  config.apiKey = process.env.REACT_APP_ARCGIS_API_KEY; 

  const callOrStoreUnitsData = async () => {
    let results;

    if (trucksListGS.length === 0) {
      results = await getUnitsData(UserIdGuid, SessionId);    
    }
    else{
      results = trucksListGS;
    } 
    results.forEach(element => {
      element.attributes.display = 1; 
    }); 
    setAllFeatures(results); 

    if (trucksListGS.length === 0) {
      setTrucksListGS(results); 
    }

    let found = results.filter((el) => el.attributes.name === truckNameParam  );
    setSelectedTruckFromUrl(found); 

  }//callOrStoreUnitsData

  const searchListNameChange = (e)=>{ 
      setTimeout(() => {   
        setTruckName(e.target.value)
      }, 200); 
  } //searchListNameChange 

  useEffect(()=>{  //runs at least once,  when the component mounts 

    document.title = "G4S History - Dashboard";  
    
   
    callOrStoreUnitsData(); 
     
 
  },[]) //use effect 


  useMemo(() => { // truck name changes 

    let newarray = allFeatures.slice().map((e)=>{ 
        if (e.attributes.name.toLowerCase().includes(truckName.toLowerCase())) { 
            e.attributes.display = 1; 
            return e;
        }
        else{
            e.attributes.display = 0; 
            return e;
        }
    }) ;
    setAllFeatures(newarray);
    
} , [truckName]);

const selectTruck = (checked,feature) => { 
  // let newarray = orignalTrucks.slice().map((e)=>{   



  setAllFeatures((prevArray) => {
    // Copy the array and the objects inside it
    let newarray = prevArray.map(item => ({ ...item })); 
    console.log('Initial newarray:', newarray);
  
    // Find the index of the object you're looking for
    const foundIdx = newarray.findIndex(el => el.attributes.name === feature.attributes.name);
  
    if (foundIdx !== -1) {
      const [found] = newarray.splice(foundIdx, 1); // Remove the object from its original position
      console.log('Found object:', found);
  
      newarray.unshift(found); // Add the found object to the start of the array
      console.log('After unshift:', newarray);
    } else {
      console.warn('Object not found!');
    }

    newarray.map((e,i)=>{ 
      if (e.attributes.name === feature.attributes.name) { 
          e.attributes.history = checked;  
          return e;
      }
      else{
          e.attributes.history = false; 
          return e;
      }
    }) ; 
  
    return newarray; // Return the updated array to be set in state
  });
  

 
  setSelectedTruck(feature);
} 

useEffect(()=>{

  if (selectedTruckFromUrl && selectedTruckFromUrl.length === 1 && allFeatures.length > 0) {

    console.log(' selectedTruckFromUrl ', selectedTruckFromUrl[0], allFeatures);  
    selectTruck(true, selectedTruckFromUrl[0]); 

  }
  
}, [selectedTruckFromUrl])
  
useEffect(()=>{ // runs when timezone changes 

  let ts = 'UTC';
  let tsSting = "Last Reported (Global UTC Time)"; 

  if (timezoneGS === 'l') {
    ts = userTimezoneGS;
    tsSting = "Last Reported ("+userTimezoneGS+" Time)";  
    // setStartDateDP( dayjs.tz(startDateDP, userTimezoneGS) ); 
    setTzdp(userTimezoneGS); 
    // timeSlider.current.timeZone = userTimezoneGS;
  }
  else{  
    setTzdp('UTC'); 
    // setStartDateDP( dayjs.utc(startDateDP) ); 
    // timeSlider.current.timeZone = 'UTC';
  } 

},[timezoneGS])//timezoneGS useEffect 

useMemo(()=>{ // runs when datetime range picker changes 

  if (startDateDP && endDateDP && ( dayjs(endDateDP).unix() < dayjs(startDateDP).unix()) ) { 
    let startDateDPUTC =  dayjs.utc(startDateDP)
    let endDateDPUTC = dayjs.utc(endDateDP)
    setStartEndDateDP({startDateDP:startDateDPUTC , endDateDP:endDateDPUTC})
  }

},[startDateDP, endDateDP])//runs when datetime range picker changes 

  return (
    <div className="pageContainer"  >   
        <Grid container >  

          <Grid item xs={2} >   
            <div> 
              <div id="listHandle" style={{cursor:'e-resize'}}> <span className="listTopIcon"> < DateRangeIcon /> </span>Select Date Range </div> 
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
 
                <DateTimePicker 
                    value={endDateDP}
                    onChange={(newValue) => setEndDateDP(newValue) }
                    timezone={tzdp}
                    ampm={false}
                    maxDateTime={startDateDP}
                    format="DD/MM/YYYY hh:mm:ss"
                    size="small"
                />
                <div style={{marginTop:'10px'}} ></div>
                <DateTimePicker 
                    value={startDateDP}
                    onChange={(newValue) => setStartDateDP(newValue) }
                    timezone={tzdp}
                    ampm={false}
                    minDateTime={endDateDP}
                    format="DD/MM/YYYY hh:mm:ss"
                    size="small"
                    
                /> 

              </LocalizationProvider>
            </div>
            <div id="truckListHistory" style={{borderTop:'1px solid', paddingTop:'10px'}}>
              <div id="listHandle" style={{cursor:'e-resize'}}> <span className="listTopIcon"> < LocalShippingIcon /> </span>Select Truck </div>
                <input 
                  id="search-list-name" 
                  type="text"
                  placeholder="Search by Name"  
                  onChange={(e)=>{searchListNameChange(e)}} 
                  style={{margin:'5px auto 0 auto', width:'100%'}} 
                />

              {
                    allFeatures && allFeatures.length>0 ? 
                    <div  style={{ height:'calc(100vh - 293px)',overflowX:'scroll'}}>
                    {
                        allFeatures.map((e)=>(
                        <Box key = {e.attributes.uid}  className= { 'listBox ' + (e.attributes.display === 1 ? 'showElement' : 'hideElement') }  >  
                            <div className="listItemTitle" >
                                
                                <Grid container  >

                                  <Grid item xs={10} sx={{fontSize:'12px', fontWeight:'bold', paddingTop:'10px'}}> 
                                      {e.attributes.name} 
                                  </Grid> 
                                  <Grid item xs={2} >   
                                    <Checkbox 
                                        onClick={(event) => {selectTruck(event.target.checked, e);}}
                                        size="small"
                                        checked={e?.attributes?.history}
                                        icon={<RadioButtonUncheckedIcon/>} 
                                        checkedIcon={<RadioButtonCheckedIcon />}
                                        sx={{marginRight:'0px', paddingRight:'0px'}} 

                                         
                                    />  
                                  </Grid>
    
                                </Grid>

                            </div> 

                        </Box>
                        )) 
                    }
                    </div>
                    :
                    <div>
                        <Box sx={{textAlign:'center', margin:'5px auto', width:'100%'}}> 
                        <div>
                            Loading  
                        </div>
                        </Box>
                    </div>
                }
            </div>
            
          </Grid>{/* list of trucks */}   
          
          <Grid item xs={10}  >  
            {/* <div id='mapcont' ref={mapcont}  ></div>        */}
            <HistoryMap truck={selectedTruck} startEndDateDP={startEndDateDP}  />
          </Grid>   
 

          </Grid>
    </div>
  )
}

export default History

