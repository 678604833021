//rafce 
import * as React from 'react';   
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button'; 
import Grid from '@mui/material/Grid';  
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {useEffect, useState, useRef } from "react"; 
import { useMeasurements } from '../common/store/measurements';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Menu from '@mui/material/Menu'; 


const Header = () => { 

  const setTimezoneGS = useMeasurements((state)=>state.setTimezone);
  const setUserTimezoneGS = useMeasurements((state)=>state.setUserTimezone); 
  const [timezone, setTimezone] = useState('l'); 
  let currentPageTitle = 'Live'
  const navigate = useNavigate(); 
  const location = useLocation();    
  
  if (location.pathname.includes('history')) {
    currentPageTitle = 'History';
  }
  else if(location.pathname.includes('live')){ 
    currentPageTitle = 'Live';
  }
  else if(location.pathname.includes('')){ 
    currentPageTitle = 'Live';
  }
  else{ 
    currentPageTitle = 'not found';
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 

  useEffect(()=>{
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezoneGS(tz);
    console.log('User Timezone local ', tz);   
    if (window.localStorage.getItem("measurements")) {
      let existingTz = JSON.parse(window.localStorage.getItem("measurements")).timezone; 
      setTimezone(existingTz);
      setTimezoneGS(existingTz); 
    }

    

  },[]) //useEffect, runs once


  const handleChange = (event) => {
    console.log(' handleChange header ', event.target.value);
    setTimezone(event.target.value);
    setTimezoneGS(event.target.value); 
    if (window.localStorage.getItem("measurements")) {
      let existingMeasurements = JSON.parse(window.localStorage.getItem("measurements")); 
      let newMeasurements = {...existingMeasurements, timezone:event.target.value};
      window.localStorage.setItem("measurements", JSON.stringify(newMeasurements));
    }
    else{
      window.localStorage.setItem("measurements", JSON.stringify({timezone:event.target.value}));
    }
    
  }; 

  return (
    <header >  
        
        <Grid container sx={{padding:'5px'}} >
            <Grid item xs={6} sx={{ textAlign:'left', fontSize:'9px'  , fontWeight:'normal'  }}> 
              <img src={"/logo.jpg"} style={{width:'40px'}} />
              <h1 style={{display:'inline-flex',margin:'0', padding:'0', margin:'-10px 0 0 7px'}}>dashboard <span style={{margin:'0 0 0 50px'}}>[{currentPageTitle}]</span></h1> 
            </Grid>
            <Grid item xs={6} sx={{ textAlign:'right' }}>   

              <span style={{marginRight:'5px', fontSize:'14px'}}> timezone : </span>
              <select 
                onChange={handleChange}
                value={timezone}
              >
                <option value="l">local</option>
                <option value="u">UTC</option>
              </select>

                <Button
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <MenuIcon/>
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={()=>{handleClose()}} 
                >
                  <MenuItem onClick={()=>{handleClose(); navigate("/live"); }} >Live</MenuItem>
                  <MenuItem onClick={()=>{handleClose(); navigate("/history"); }} >History</MenuItem> 
                </Menu>

 

            </Grid>
        </Grid>
    </header>
  )
}

export default Header
