import Map from "@arcgis/core/Map.js";
import config from "@arcgis/core/config.js";
import MapView from "@arcgis/core/views/MapView.js";
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle.js";
import Search from "@arcgis/core/widgets/Search.js";
import Legend  from "@arcgis/core/widgets/Legend.js";
import Expand from "@arcgis/core/widgets/Expand.js";
import LayerList from "@arcgis/core/widgets/LayerList.js";
import StreamLayer from "@arcgis/core/layers/StreamLayer.js";
import { Box , Button, Drawer } from "@mui/material";
import {useEffect, useState, useRef, useMemo, useCallback, useReducer } from "react";
import {getUnits} from '../common/api/unitsAPI.js'
import { useUserStore } from "../common/store/user";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect.js";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter.js";
import Extent from "@arcgis/core/geometry/Extent.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import TimeSlider from "@arcgis/core/widgets/TimeSlider.js";
import { useMeasurements } from "../common/store/measurements.js";
import { useTrucks } from "../common/store/trucks.js";
import { useCompanies } from "../common/store/companies.js";
import Popup from "@arcgis/core/widgets/Popup.js";
import TruckList from "../components/TrackList.js";
import AlertsList from "../components/AlertsList.js";
import AlertsPie from "../components/AlertsPie.js";
import { getUnitsData } from "../common/trucks/getTrucks.js";
import { getCompaniesAndGroups } from "../common/api/companiesAPI.js";
import { Checkbox } from "@mui/material"; 
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ApartmentIcon from '@mui/icons-material/Apartment';


function MapPage() {



  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [allFeatures, setAllFeatures] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [newDataReceived, setNewDataReceived] = useState();
  const [newAlertsLive , setNewAlertsLive] = useState([]);
  const [alertsLivePerTruck , setAlertsLivePerTruck] = useState(''); 
  

  const reducer = (followFeatures, data) =>{

    // dispatch({action:'followFeature', event, feature: e })
    // dispatch({action:'unfollowAllFeatures' })
    console.log(' reducer data ', data.action, data.feature, data.event);


    if (data.action === 'followFeature') {

      if (data.event.target.checked === true) {
        let newArray = [...followFeatures] ;
        newArray.push(data.feature);
        return newArray;
      }
      else{
        let newArray = [...followFeatures].filter(el => el.attributes.imei !== data.feature.attributes.imei)  ;
        return newArray;
      }

    }

    if (data.action === 'unfollowAllFeatures') {

        return [];

    }

}// reducer
const [followFeatures, dispatch] = useReducer(reducer, []);

  const timezoneGS = useMeasurements((state)=>state.timezone);
  const userTimezoneGS = useMeasurements((state)=>state.userTimezone);
  const UserIdGuid = useUserStore((state)=>state.UserIdGuid);
  const SessionId = useUserStore((state)=>state.SessionId);
  const trucksListGS = useTrucks((state)=>state.trucksList);
  const setTrucksListGS = useTrucks((state)=>state.setTrucksList);
  const companiesListGS = useCompanies((state)=>state.companiesList);
  const setCompaniesListGS = useCompanies((state)=>state.setCompaniesList);

  let datareceivedOnce = false;

  const callOrStoreUnitsData = async () => {
    let results;

    if (trucksListGS.length === 0) {
      results = await getUnitsData(UserIdGuid, SessionId);
    }
    else{
      results = trucksListGS;
    }
    if (results.length > 0) {
      results.forEach(element => {
        element.attributes.display = 1;
        let lastr;
        if (timezoneGS === 'l') {
          lastr = new Date(element.attributes.lastreportedtimeutc).toLocaleString();
        }
        else{
          lastr = new Date(element.attributes.lastreportedtimeutc).toISOString().replace("T"," ").substring(0, 19);
        }

        element.attributes.lastupdate = lastr
      });
    }
    setAllFeatures(results);

    if (trucksListGS.length === 0) {
      setTrucksListGS(results);
    }


  }//callOrStoreUnitsData


  const callOrStoreCompaniesData = async () => {
    let results;

    if (companiesListGS.length === 0) {
      results = await getCompaniesAndGroups(UserIdGuid, SessionId);
    }
    else{
      results = companiesListGS;
    } 
    console.log(' companies-', results);
    
    setAllCompanies(results);

    if (trucksListGS.length === 0) {
      setCompaniesListGS(results);
    }


  }//callOrStoreCompaniesData



  const mapcont = useRef();
  const webmap = useRef();
  const view = useRef();
  const g4s = useRef();
  const g4sConnection = useRef();
  const g4sLayerView = useRef();
  const speedingPoints = useRef();
  const speedingPointsLayerView = useRef();
  const speedingLines = useRef();
  const timeSlider = useRef();


  config.apiKey = process.env.REACT_APP_ARCGIS_API_KEY;


  useEffect( ()=>{  //runs at least once,  when the component mounts

    document.title = "G4S Live - Dashboard"

    callOrStoreUnitsData();
    callOrStoreCompaniesData();


    webmap.current = new Map({
      // const webmap = new WebMap({
      basemap: "gray-vector"
    });

    view.current = new MapView({
      map: webmap.current,
      container:mapcont.current,
      center:[ 11.40189332293807, 50.9754784932554 ],
      zoom:4,
      timeZone:'UTC',
      popup: new Popup({
        dockEnabled: true,
        dockOptions: {
          buttonEnabled: false,
          breakpoint: false
        },
        position:'top-right'
      }),
    })

    let layerList = new LayerList({
      view: view.current
    });

    const layerListExpand = new Expand({
      id:'layerListExpand',
      expandIcon: "list",
      view: view.current,
      content: layerList
    });

    let baseMapToggle = new BasemapToggle({
      view: view.current,
      nextBasemap:'satellite'
    })

    let search = new Search({
      view:view.current
    })

    const searchExpand = new Expand({
      id:'searchExpand',
      expandIcon: "search",
      view: view.current,
      content: search
    });

    const legend = new Legend ({
      view: view.current
    });

    const legendExpand = new Expand({
      id:'legendExpand',
      expandIcon: "legend",
      view: view.current,
      content: legend
    });

    view.current.ui.add(legendExpand, "top-right");
    view.current.ui.add(searchExpand, 'top-right');
    view.current.ui.add(baseMapToggle, 'top-right');
    view.current.ui.add(layerListExpand, 'top-left');


    timeSlider.current = new TimeSlider({
      playRate: 1000,
      timeVisible: true,
      // mode:'instant',
      layout:'wide'
    });


    const g4sRenderer = {
      type: "simple", // autocasts as new SimpleRenderer()
      symbol: {
        type: "simple-marker",
        path: "M14.5,29 23.5,0 14.5,9 5.5,0z",
        color: "blue",
        outline: {
          color: 'white',
          width: 1
        },
        angle: 180,
        size: 15,

      },

      visualVariables: [
        {
          type: "rotation", // indicates that symbols should be rotated based on value in field
          field: "Heading", // field containing aspect values
          rotationType: "geographic"
        },
        {
          type: "color",
          field: "Speed",
          stops: [
            {color:'black', value:0},
            {color:'yellow', value:30},
            {color:'red', value:90}
          ]
        },
        {
          type: "size",
          valueExpression: "$view.scale",
          stops: [
            { size: 18, value: 288895 },
            { size: 24, value: 2311162 },
            { size: 29, value: 18489297 },
            { size: 34, value: 147914381 }
          ]
        }
      ]
    };
    const nameClass = {
      labelPlacement: "above-center",
      labelExpressionInfo: {
        expression: "$feature.Name"
      },
    };
    nameClass.symbol = {
      type: "text", // autocasts as new TextSymbol()
      font: {
        size: 10
      },
      color: "white",
      haloColor: 'black',
      haloSize: 1
    };
    g4s.current = new StreamLayer({
      url : 'https://mapsrv19.terra.gr/server/rest/services/Tracking-lastposition-updated-stream-out/StreamServer',
      // url: "https://mapsrv19.terra.gr/server/rest/services/tracking-lastposition-all-service-out/StreamServer",
      title:'Positions Live Stream',
      renderer:g4sRenderer,
      labelingInfo: [nameClass],
      timeInfo: {
        trackIdField: "Imei",
        startField:"LastReportedTimeUTC",
        endField:"LastReportedTimeUTC"
      },
      purgeOptions: {
          maxObservations: 10,
      },
      definitionExpression:'Speed > 10'
    })

    view.current.whenLayerView(g4s.current)
    .then((layerView) => {
        g4sLayerView.current = layerView;

        g4sLayerView.current.on('data-received',  (d)=>{
          console.log('data-received ',d.attributes.Speed , d.attributes.companyname);
          setNewDataReceived(d);

          if (datareceivedOnce === false) {
            setOpenBackdrop(false);

            datareceivedOnce = true;
            setTimeout(async()=>{
              let ext = await g4sLayerView.current.queryExtent();
              view.current.goTo(ext.extent)
              .catch((error)=> {
                console.error('first extent reset goTo error ', error);
              });

            }, 10000)
          } //data received Once


        })

    },(error) => {
      console.log('we dont have the layer view: ', error)
    });


    const speedingPointsRenderer = {
      type: "unique-value",
      field: "status",
      uniqueValueInfos: [
        {
        value: "Ongoing",
        label: "Ongoing",
          symbol: {
            type: "web-style",
            name: 'circle-5',
            styleName: "Esri2DPointSymbolsStyle",
          },
        },
        {
          value: "Started",
          label: "Started",
            symbol: {
              type: "web-style",
              name: 'SafetyCautionAlerts',
              styleUrl: "https://cdn.arcgis.com/sharing/rest/content/items/36359a4a8f3143b6bf44d5688e007900/data"
            },
          },
          {
            value: "Ended",
            label: "Ended",
              symbol: {
                type: "web-style",
                name: 'SafetyCautionAlerts 1',
                styleUrl: "https://cdn.arcgis.com/sharing/rest/content/items/36359a4a8f3143b6bf44d5688e007900/data"
              },
            },
      ]
    };
    const nameClassspeedingPoints = {
      labelPlacement: "above-center",
      labelExpressionInfo: {
        expression:
        `
              var status = $feature.status;
              var fn = $feature.source___speed +" "+  $feature.source___speedmeasure +" - "+ $feature.source___lastreportedtimelocal + " local time" ;
              if(status == 'Started'){
                fn = $feature.source___speed +" "+  $feature.source___speedmeasure +" - Started : "+ $feature.source___lastreportedtimelocal + " local time" ;
              }
              if(status == 'Ended'){
                fn = $feature.source___speed +" "+  $feature.source___speedmeasure +" - Ended : "+ $feature.source___lastreportedtimelocal + " local time" ;
              }
              return fn
              `

      },
      minScale: 573050
    };
    nameClassspeedingPoints.symbol = {
      type: "text", // autocasts as new TextSymbol()
      font: {
        size: 9,
      },
      color: "white",
      haloColor: 'black',
      haloSize: 1,
      minScale: 57305
    };


    let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    let fullTimeExtentStart = yesterday.getTime();
    let fullTimeExtentEnd = new Date();


    speedingPoints.current = new FeatureLayer({
      // url: "https://mapsrv19.terra.gr/server/rest/services/Hosted/3d-fs-point-alerts-cumul-out/FeatureServer/0",
      url: "https://mapsrv19.terra.gr/server/rest/services/Hosted/TrackingPointSpeedingAlert/FeatureServer/0",
      title:'Speeding Alerts',
      refreshInterval : 0.3,
      renderer:speedingPointsRenderer,
      labelingInfo: [nameClassspeedingPoints], 
      timeExtent:{
        start:fullTimeExtentStart,
        end:fullTimeExtentEnd
      },
      timeInfo: {
        trackIdField: "id",
        startField: "source___LastReportedTimeUTC",
        endField: "source___LastReportedTimeUTC",
        fullTimeExtent:{
          start:fullTimeExtentStart,
          end:fullTimeExtentEnd
        },
        interval: {
          unit: "hours",
          value: 2
        }
      },
    });

    webmap.current.add(speedingPoints.current,1);

    speedingPoints.current.on("refresh", (event)=>{ 
      let flag = Date.now();
      setNewAlertsLive(flag);
    });



    view.current.whenLayerView(speedingPoints.current)
    .then((layerView) => {
      speedingPointsLayerView.current = layerView;

      let nowDateStart = new Date(Date.now());
      let nowDateEnd = new Date(Date.now());
      let startDate = nowDateStart.setHours(nowDateStart.getHours() + 4);
      let endDate = nowDateEnd.setHours(nowDateEnd.getHours() - 24);


        timeSlider.current.fullTimeExtent = {
          start:endDate,
          end:startDate
        };
        timeSlider.current.timeExtent = {
          start:startDate,
          end:startDate
        };
        timeSlider.current.stops = {
          interval: {
            value: 30,
            unit: "minutes"
          }
        } 

    }, (error) => {
      console.log('we dont have the layer view: ', error)
    }) //speedingPoints on layerview






    timeSlider.current.watch('timeExtent', ()=>{

      let start = timeSlider.current.timeExtent.start.toISOString().replace("T"," ").substring(0, 19);
      let end = timeSlider.current.timeExtent.end.toISOString().replace("T"," ").substring(0, 19);

      if (timeSlider.current.timeZone !== 'UTC') {
        let startLocal = new Date(timeSlider.current.timeExtent.start);
        let endLocal = new Date(timeSlider.current.timeExtent.end);
        start = startLocal.getFullYear() +"-"+ (startLocal.getMonth()+1) +"-"+ startLocal.getDate() +" "+ startLocal.getHours()+":"+startLocal.getMinutes()+":"+startLocal.getSeconds();
        end = endLocal.getFullYear() +"-"+ (endLocal.getMonth()+1) +"-"+ endLocal.getDate() +" "+ endLocal.getHours()+":"+endLocal.getMinutes()+":"+endLocal.getSeconds();
      }

      speedingPoints.current.featureEffect = new FeatureEffect({
        filter: new FeatureFilter({
          where: `timestamp > TIMESTAMP '${start}' AND timestamp < TIMESTAMP '${end}' AND name = 'PointSpeedIncident'` 
        }),
        excludedEffect: "opacity(0%)",
        includedEffect: "opacity(100%)"
      });

      // speedingLines.current.featureEffect = new FeatureEffect({
      //   filter: new FeatureFilter({
      //     where: `timestamp > TIMESTAMP '${start}' AND timestamp < TIMESTAMP '${end}'`
      //   }),
      //   excludedEffect: "opacity(0%)",
      //   includedEffect: "opacity(100%)"
      // });

    })

    view.current.ui.add(timeSlider.current, 'bottom-leading' );

    webmap.current.add(g4s.current,2);

    return () => {
      // alert('testing before unmount') ;
      g4s.current.destroy();
      // speedingPoints.current.destroy();
      webmap.current.destroy();
    }


  },[]) //use effect



   useEffect(()=>{
    console.log(' usefeect newDataReceived ', newDataReceived);
    console.log(' usefeect newDataReceived followFeatures ', followFeatures);
    if (followFeatures.length>0 && followFeatures.find((obj) => obj.attributes.imei === newDataReceived.attributes.Imei )  ) {
      followFeaturesActions(followFeatures);
    }
  },[newDataReceived])//useEffect newDataReceived

  useEffect(()=>{
    followFeaturesActions(followFeatures);
    console.log(' useEffect followFeatures ', followFeatures );
  },[followFeatures])//useEffect followFeatures

  const followFeaturesActions = (followFeatures) =>{
    if (followFeatures.length>0) {

      let basicExt = new Extent({
        xmin: followFeatures[0].geometry.x,
        xmax: followFeatures[0].geometry.x,
        ymin: followFeatures[0].geometry.y,
        ymax: followFeatures[0].geometry.y
      });
      let whereClause = '';
      for (const el of followFeatures) {
        let resExtent = new Extent({
          xmin: el.geometry.x,
          xmax: el.geometry.x,
          ymin: el.geometry.y,
          ymax: el.geometry.y
        });
        basicExt = basicExt.union(resExtent);
        whereClause = whereClause +" '"+ el.attributes.name +"' OR Name = ";
      }

      whereClause = whereClause.substring(0, whereClause.length - 10);

      //where query clause must be of form : Name = 'SI GT 417' ΟR Name ='SI GT 434'
      g4s.current.featureEffect = new FeatureEffect({
        filter: new FeatureFilter({
          where: `Name = ${whereClause} `
        }),
        excludedEffect: "opacity(50%)",
        includedEffect: "opacity(100%)"
      });

      view.current.goTo({ target: basicExt.expand(1.2)})
      .catch((error) => {
        console.error('follow goTo error ', error);
      });

    }
    else{
      showAllFeaturesOnMap();
    }
  }//followFeaturesActions





  useEffect(()=>{ // runs when timezone changes

    let ts = 'UTC';
    let tsSting = "Last Reported (Global UTC Time)";

    if (timezoneGS === 'l') {
      ts = userTimezoneGS;
      tsSting = "Last Reported ("+userTimezoneGS+" Time)";
      view.current.timeZone = userTimezoneGS;
      // timeSlider.current.timeZone = userTimezoneGS;
    }
    else{
      view.current.timeZone = 'UTC';
      // timeSlider.current.timeZone = 'UTC';
    }

    //var d= ChangeTimeZone($feature.LastReportedTimeUTC, "`+ ts +`");

    let popupTemplateDynamic = {
      title: "Truck : {NAME} | Group : {groupname}",
      content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "expression/country"
          },
          {
            fieldName: "Address",
            label: "Address",
          },
          {
            fieldName: "expression/lastReported",
          },
          {
            fieldName: "expression/driver"
          },
          // {
          //   fieldName: "expression/drivercode"
          // },
          {
            fieldName: "expression/speed"
          },
          {
            fieldName: "Ignition",
            label: "Ignition",
          },
          {
            fieldName: "EngineStatus",
            label: "Engine Status",
          }


        ]
      }],
      // autocasts to ExpressionInfo class
      expressionInfos: [
        {
          name: "lastReported",
          title: tsSting,
          expression: `

              var d= $feature.LastReportedTimeUTC;
              var df = Text(d, "M/D/Y, hh:mm:ss A");
              return df
          `
        },
        {
          name: "country",
          title: "Country",
          expression: `
            return \`\${$feature.municipality} ,  \${$feature.country}
            \`
          `
        },
        {
          name: "speed",
          title: "Speed",
          expression: `
            return \`\${$feature.Speed} ,  \${$feature.SpeedMeasure}
            \`
          `
        },
        {
          name: "driver",
          title: "Driver (first/last name)",
          expression: `
          var firstName = $feature.DriverFirstName;
          var lastName = $feature.DriverLastName;
          var fn = IIf(IsEmpty(firstName),"ND", $feature.DriverFirstName)
          var ln = IIf(IsEmpty(lastName),"ND", $feature.DriverLastName)
          return fn +" , "+ ln
          `
        },
        {
          name: "drivercode",
          title: "Driver Code",
          expression: `
          var drivercode = $feature.DriverCode;
          var dc = IIf(IsEmpty(drivercode),"ND", $feature.DriverCode)
          return dc
          `
        }
      ]
    };

    g4s.current.popupTemplate = popupTemplateDynamic;

    // let newarray = allFeaturesCopy.slice().map((e)=>{
    //     let lastrep ;
    //     if (timezoneGS === 'l') {
    //       lastrep = new Date(e.attributes.lastreportedtimeutc).toLocaleString();
    //     }
    //     else{
    //       lastrep = new Date(e.attributes.lastreportedtimeutc).toISOString().replace("T"," ").substring(0, 19);
    //     }
    //     e.attributes.lastupdate = lastrep;
    //     return e;
    // }) ;

    // setAllFeaturesCopy(newarray);

  },[timezoneGS])//timezoneGS useEffect


  const handleMapActions = useCallback((data) =>{
    // mapActions({action:'zoomToFeature', feature: e, name : name});
    // mapActions({action:'showAllFeatures'});

    console.log(' handleMapActions ', data);

    if (data.action === 'zoomToFeature') {
      view.current.goTo({target: [data.feature.geometry.x, data.feature.geometry.y], zoom:9})
      .catch((error)=> {
        console.error('zoomToFeature goTo error ', error);
      });

      g4s.current.featureEffect = new FeatureEffect({
        filter: new FeatureFilter({
          where: `Name = '${data.name}'`
        }),
        excludedEffect: "opacity(50%)"
      });
    }
    if (data.action === 'showAllFeatures') {
      g4s.current.featureEffect = new FeatureEffect({
        filter: new FeatureFilter({
          where: "1=1"
        }),
        includedEffect: "opacity(100%)"
      });

      view.current.goTo({target:[ 11.40189332293807, 50.9754784932554 ],zoom:4})
      .catch((error)=> {
        console.error('showAllFeaturesOnMap goTo error ', error);
      });
    }

  }, []);//handleMapActions

  const featureAlerts = useCallback((data) =>{
    console.log(' map page featureAlerts ', data.attributes.name);

    setAlertsLivePerTruck(data.attributes.name)
  }, [alertsLivePerTruck]);//featureAlerts




  const showAllFeaturesOnMap = () => {

    g4s.current.featureEffect = new FeatureEffect({
      filter: new FeatureFilter({
        where: "1=1"
      }),
      includedEffect: "opacity(100%)"
    });

    view.current.goTo({target:[ 11.40189332293807, 50.9754784932554 ],zoom:4})
    .catch((error)=> {
      console.error('showAllFeaturesOnMap goTo error ', error);
    });


  }//showAllFeaturesOnMap


  const handleFilterChange = (tf, company, e) =>{

    console.log('handleFilterChange ', tf, company, e);
    let allCompGroup = allCompanies.slice();
    //a company is clicked
    if (company === 1 ) {
      if (e.company==='All Companies and Groups' && tf === true) {
        allCompGroup.forEach(el => {
          el.select = false
          el.groups.forEach(elg => {
            elg.select = false
          });
        });
        allCompGroup[0].select = true;
        setAllCompanies(allCompGroup)
      }
      if (e.company==='All' && tf === false) { 
        allCompGroup[0].select = false;
        setAllCompanies(allCompGroup)
      }
      if (e.company!=='All' ) { 
        allCompGroup[0].select = false;
        allCompGroup.forEach(el => {
          if (el.company === e.company) {
            el.select = tf 
            el.groups.forEach(elg => {
              elg.select = tf
            });             

          } 
        }); 
        setAllCompanies(allCompGroup)
      }
    }
    //a group of company is clicked
    if (company !== 1 ) {
      allCompGroup[0].select = false;
      allCompGroup.forEach(el => {
        if (el.company === company) {
          el.select = false
          el.groups.forEach(elg => {
            if (elg.group === e.group) {
              elg.select = tf  
            } 
          });             

        } 
      }); 
      setAllCompanies(allCompGroup)

    }

    let whereClause = [];
    let whereClauseFinal = '';
    allCompGroup.forEach(element => {
      if (element.select === true) {
        whereClause.push("companyname = '" + element.company+ "' " );
      }
      else{
        element.groups.forEach(elementg => {
          if (elementg.select === true) {
            whereClause.push("groupname = '" + elementg.group+ "' " );
          }
        });
      } 
    });

    if (whereClause.length ==0) {
      allCompGroup[0].select = true;
      setAllCompanies(allCompGroup);
      whereClause.push('Speed > 10')
    } 
    whereClauseFinal = whereClause.join(' AND ') 

    console.log('  definitionExpression filters   ', whereClauseFinal);
    

    // if (e.target.value === 'Speed') { 
    //   g4s.current.definitionExpression = 'Speed > 10';
    // }
    // else{ 
    //   let whereClause = "companyname = '" + e.target.value + "' AND Speed > 10" ; 
     g4s.current.definitionExpression = whereClauseFinal;
    // }
  }



  return (
    <div className="pageContainer"  >
      {/* <Drawer
        open={pointsDrawer}
        variant="persistent"
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
            top: '21px',
            width:'300px!important'  ,
            height:'calc(100vh - 21px)',
            overflowY: 'unset',
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            top: '21px',
            width:'300px!important'  ,
            height:'calc(100vh - 21px)' ,
            overflowY: 'unset',
          },
        }}
      >

        <Button
          onClick={()=>{setPointsDrawer(!pointsDrawer)}}
          sx={{
            position: 'absolute',
            right:'-48px',
            top: '150px',
            backgroundColor: 'black',
            color: 'white',
            visibility:'visible',
            width:'34px!important',
            minWidth:'34px!important',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            }
          }}
        >
          <LocalShippingIcon />
        </Button>

        list of trucks goes here if drawer returns




      </Drawer>  */}



      <Grid container >

        <Grid item xs={2} >
          {/* <select 
            onChange={handleFilterChange} 
            style={{width:'100%', margin:'0 auto 5px auto', textAlign:'center'}}
        >
            <option value="Speed">All Moving Trucks</option>
            <option value="GEORGI Gmbh  and  Co. KG Transporte">GEORGI Gmbh  and  Co. KG Transporte</option>
            <option value="EUROCATERING">EUROCATERING</option>
            <option value="G4S CASH">G4S CASH</option> 
          </select> */}
          <Box  style={{height: 'calc(50vh - 45px)'}}    sx={{ overflowY:'scroll', border:'1px solid'}}> 
          <div id="listHandle" style={{cursor:'e-resize'}}> <span className="listTopIcon"> < ApartmentIcon /> </span>Companies </div>
            {
              allCompanies && allCompanies.length > 0 ?
              <div  style={{ overflowY:'scroll'}}>
                  {allCompanies.map((el) => (
                    <Box key = {el.company}  className= { 'listBox ' + (el.display === 1 ? 'showElement' : 'hideElement') }  >  
                      <div className="listItemTitle" >{el.company}
                        <Checkbox  
                          onClick={(event) => {handleFilterChange(event.target.checked, 1, el);}}
                          size="small"
                          checked={el?.select}
                          icon={<CheckBoxOutlineBlankIcon/>} 
                          checkedIcon={<CheckBoxIcon />}
                          sx={{marginRight:'0px', paddingRight:'0px'}}       
                          
                        />  
                      </div>  

                    	{
                        el.groups.length>0 &&   
                        <details>
                          <summary style={{textAlign:"left", cursor:'pointer'}}>{el.company} Groups</summary>
                          {el.groups.map((e) => (
                            <div className="listSubItemTitle" >{e.group}
                              <Checkbox  
                                onClick={(event) => {handleFilterChange(event.target.checked, el.company, e);}}
                                size="small"
                                checked={e?.select}
                                icon={<CheckBoxOutlineBlankIcon/>} 
                                checkedIcon={< CheckBoxIcon/>}
                                sx={{marginRight:'0px', paddingRight:'0px'}}       
                                
                              />  
                            </div> 
                          ))}
                        </details>
                      }

                    </Box>
                  ))}
                </div>
                :
                <div>
                </div>
            }
          </Box>
          {/* <TruckList trucks={allFeatures} trucksNum={allFeatures.length} newData={newDataReceived} mapActions={handleMapActions} dispatch={dispatch} featureAlerts={featureAlerts} />  */}
          <TruckList trucks={allFeatures} trucksNum={allFeatures.length}  mapActions={handleMapActions} dispatch={dispatch} featureAlerts={featureAlerts} /> 

        </Grid>{/* list of trucks */}

        <Grid item xs={8}  >
          <div id='mapcont' className="mapcont" ref={mapcont}  ></div>
        </Grid>

        <Grid item xs={2} >
            <Box  style={{height: 'calc(50vh - 45px)'}}    sx={{ overflowY:'scroll', border:'1px solid'}}> 
              <AlertsList mapActions={handleMapActions} flag = {newAlertsLive} alertsLivePerTruck={alertsLivePerTruck} />
            </Box>
            <AlertsPie />
        </Grid>

      </Grid>


      <Backdrop
        sx={{ color: '#fff', zIndex: 300 }}
        //open={openBackdrop}
        open={false}
      >
        <Box sx={{textAlign:'center', margin:'5px auto', width:'100%'}}>
          <div>
            <CircularProgress color="inherit" />
          </div>
          <div>
            Loading signal streams
          </div>
        </Box>
      </Backdrop>
    </div>
  );
}

export default MapPage;

